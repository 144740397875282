import { css } from "@emotion/react";
import { ITheme, mediaQueries } from "../../theme";

const styles = {
  OurServicesWrapper: (t: ITheme) => {
    return css``
  },
  ServicesWrapper: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;

    ${mediaQueries.lg} {
      grid-template-columns: repeat(4, 1fr);
    }
  `,
  ServicePanel: css`
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  `,
}

export default styles;