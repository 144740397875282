import { Fragment } from "react";
import { Heading } from "../Heading";
import { Size } from "../Heading/types";
import { ContentProps } from "./types";
import styles from "./styles";

export const Content = ({
  title,
  text,
  bgColor = "var(--green)",
  textColor,
  centerTitle = true,
  image = "",
  children
}: ContentProps) => {
  return (
    <div css={styles.ContentWrapper(bgColor, textColor, image)}>
      <div>
        <Heading styling={styles.Title} size={Size.H4} center={centerTitle}>{title}</Heading>
        {Array.isArray(text) && (
          text.map((t, i) => {
            return (
              <Fragment key={i}>
                <Heading size={Size.H4_SMALL}>{t}</Heading>
                <br /><br />
              </Fragment>
            );
          })
        )}
        {children}
      </div>
    </div>
  );
};