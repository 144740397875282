import { css } from "@emotion/react";
import theme, { ITheme } from "../../theme";

const styles = {
  OptionsWrapper: (t: ITheme, inSidebar: boolean) => css`
    display: flex;
    width: 100%;
    justify-content: space-around;
    color: ${t.color};
    align-items: center;
    ${inSidebar ? `height: 60%; padding-top: 2rem;` : ''}

    a:nth-of-type(2) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    a:nth-of-type(3) {
      margin-right: 1rem;
    }

    ${theme.mediaQueries.lg} {
      justify-content: center;
      a:nth-of-type(2) {
        margin-left: 6rem;
        margin-right: 6rem;
      }
      a:nth-of-type(3) {
        margin-right: 6rem;
      }
    }
  `,
};

export default styles;