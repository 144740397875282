import { css } from "@emotion/react";
import { mediaQueries } from "../../../../theme";

const styles = {
  ButtonPrevious: css`
    position: absolute;
    left: -1.5rem;
    top: 50%;
    z-index: 1;
    
    ${mediaQueries.md} {
      left: -2.8125rem;
    }
  `,
};

export default styles;