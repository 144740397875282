import { css } from "@emotion/react";
import { mediaQueries } from "../../../../theme";

const styles = {
  ButtonNext: css`
    position: absolute;
    right: -1.5rem;
    top: 50%;
    z-index: 1;

    ${mediaQueries.md} {
      right: -2.8125rem;
    }
  `,
};

export default styles;