import { css } from "@emotion/react";
import { mediaQueries } from "../../../theme";
// import theme from "../../../theme";
import { ArrowDirection, IArrowProps } from "./types";

type ArrowStyleProps = Pick<IArrowProps, "direction" | "colour" | "width">

const styles = {
  Arrow: ({ direction, colour = 'black', width = 8 }: ArrowStyleProps) => {
    const baseArrow = `
      border: solid ${colour};
      border-width: 0 ${width}px ${width}px 0;
      display: inline-block;
      padding: ${width * 1.5}px;
      cursor: pointer;

      ${mediaQueries.md} {
        :hover {
          border-color: white;
        }
      }
    `;

    let rotation = '-45deg';

    switch (direction) {
      case ArrowDirection.RIGHT: rotation = '-45deg'; break;
      case ArrowDirection.LEFT: rotation = '135deg'; break;
      case ArrowDirection.UP: rotation = '-135deg'; break; 
      case ArrowDirection.DOWN: rotation = '45deg'; break;
    }

    return css`
      ${baseArrow}
      transform: rotate(${rotation});
      -webkit-transform: rotate(${rotation});
    `;
  }
};

export default styles;