import { css } from "@emotion/react";
import { ITheme, mediaQueries } from "../../theme";

const styles = {
  ContactUsWrapper: (t: ITheme) => {
    return css`
      display: flex;
      justify-content: center;
      color: ${t.colorAlt};
      padding: 4rem 2rem 2rem 2rem;
    
      > div {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        ${mediaQueries.xLg} {
          width: 50%;
        }
      }
    `;
  },
  ContactForm: css`
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    width: 100%;
    padding-top: 2rem;

    input {
      height: 2rem;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }

    textarea {
      height: 10rem;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }

    button {
      width: 15rem;
      align-self: center;
    }
  `,
  // TODO Not ideal to do this like this, should refer to global styles
  ExtraPadding: css`
    margin-bottom: 1rem;
  `,
  MorePadding: css`
    margin-bottom: 3rem;
  `
};

export default styles;