import { Fragment, RefObject, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { animations } from "../Shared/Animations";
import { Heading } from "../Shared/Heading";
import { Size } from "../Shared/Heading/types";
import sharedStyles from "../Shared/Styles";
import styles from "./styles";

import NeedHelp from "../../assets/need-help.jpg";
import { Panel } from "../Shared/Panel";
import ThemeContext from "../../utils/ThemeContext";
import useWindowSize from "../../hooks/use-window-size";
import { breakpoints } from "../../theme";

interface IAboutUsProps {
  aboutUsRef: RefObject<HTMLDivElement>;
}

export const AboutUs = ({
  aboutUsRef
}: IAboutUsProps) => {
  const { theme } = useContext(ThemeContext);
  const [showLeftPanel, setShowLeftPanel] = useState<boolean>(false);
  const { width } = useWindowSize();

  if (!showLeftPanel && width >= breakpoints.lg) {
    setShowLeftPanel(true);
  } else if (showLeftPanel && width < breakpoints.lg) {
    setShowLeftPanel(false);
  }

  return (
    <div css={styles.AboutUsWrapper} ref={aboutUsRef}>
      <Panel 
        title={
          <Heading styling={[styles.Title, animations.slideInFromLeft('1s')]} size={Size.H4}>About Us</Heading>  
        }
        leftPanel={
          showLeftPanel && (
            <img css={animations.fadeIn('2s')} src={NeedHelp} alt="Help"></img>
          )
        }
        rightPanel={
          <Fragment>
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H4_SMALL}>We are a firm of Chartered Accountants based in Lisburn, offering a full range of professional services to clients throughout Northern Ireland.</Heading>
            <br />
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H4_SMALL}>We deliver a high quality tailored service to your business’s individual needs, offering meaningful advice to help you succeed with your personal goals.</Heading>
            <br />
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H4_SMALL}>We want our clients to feel we are part of their team, a sounding board for ideas, here to offer professional advice as part of your team. (This in different coloured font).</Heading>
            <br />
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H4_SMALL}>
              The accountancy industry like all industries is changing, gone are the days when you hear from your accountant once a year. To be successful, businesses need to be able to respond quickly.
              Waiting for an annual set of accounts, that provides outdated information can leave your business vulnerable.
            </Heading>
          </Fragment>
        } 
      />

      <div css={styles.MissionStatementWrapper}>
        <div>
          <br />
          <Heading styling={[styles.WeOfferMore, animations.slideInFromLeft('1s')]} size={Size.H4}>We offer so much more...</Heading>
          <br />
          <br />
          <Heading styling={animations.slideInFromLeft('1s')} size={Size.H4_SMALL}>
            As a part of your team, you can be assured of expert accounting advice, tailored to your personal needs, budget, business needs and the challenges you face.
            <br />
            <br />
            To find out how we can help you and your business, <Link to="/contact-us" css={[styles.Link, sharedStyles.Link(theme)]}>get in touch today</Link>, we offer a free, no obligation consultation.  
          </Heading>
        </div>
      </div>
    </div>
  );
  
};