import { ReactNode } from "react";
import styles from "./styles";

interface IPanelProps {
  title: string | ReactNode;
  leftPanel?: ReactNode;
  rightPanel?: ReactNode;
  children?: Array<ReactNode> | ReactNode;
}

export const Panel = ({
  title,
  leftPanel,
  rightPanel
}: IPanelProps) => {
  return (
    <div css={styles.PanelWrapper}>
      {title}
      <div css={styles.PanelContent}>
        {leftPanel && <div css={styles.LeftPanel}>{leftPanel}</div>}
        {rightPanel && <div css={styles.RightPanel}>{rightPanel}</div>}
      </div>
    </div>
  );
}