import { SerializedStyles } from "@emotion/react";
import { useContext } from "react";
import { ITestimonial } from "../../utils/testimonials";
import ThemeContext from "../../utils/ThemeContext";
import { Testimonial } from "../Testimonial/Testimonial";
import styles from "./styles";

interface ITestimonialsProps {
  tests: Array<ITestimonial>;
  style?: SerializedStyles;
}

export const Testimonials = ({
  tests,
  style,
}: ITestimonialsProps) => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <div css={[styles.TestimonialsWrapper(theme), style]}>
      {tests.map((t, i) => <Testimonial key={t.author} {...t} i={i} />
    )}
    </div>
  );
};
