import { css } from "@emotion/react";
import theme, { ITheme } from "../../../theme";

const styles = {
  SocialMediaWrapper: (inSidebar: boolean) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      ${inSidebar ? 'margin-top: 3rem;' : null}
    `;
  },
  ContactWrapper: css`
    display: flex;
    & {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
    > {
      :link, :visited  {
        color: white;
      }
    }
  `,
  Email: css`
    :hover {
      color: ${theme.appTheme.fg};
    }
  `,
  PhoneNumber: css`
    margin: 0 0.75rem;
    :hover {
      color: ${theme.appTheme.fg};
    }
  `,
  Facebook: css`
    margin-right: 1rem;
    :hover {
      color: ${theme.appTheme.fg};
    }
  `,
  ThemeToggleWrapper: css`
    display: flex;
  `,
  ThemeBtn: css`
    width: 3rem;
    height: 1.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-right: 0.5rem;
  `,
  TealBtn: (theme: ITheme) => {
    return css`
      background-color: ${theme.bg};
      border: 1px solid ${theme.icon};
    `;
  },
  WhiteBtn:  (theme: ITheme) => {
    return css`
      background-color: ${theme.bg};
      border: 1px solid ${theme.icon};
    `;
  },
  TooltipContainer: css`
    position: relative;
    > div {
      left: -55px;
      width: 120px;
    }
  `,
};

export default styles;