import { SerializedStyles, css } from "@emotion/react";
import { NotificationType } from "./types";

const getBgColor = (type: NotificationType): SerializedStyles => {
  let bgColor = "var(--light-blue)";

  if (type === NotificationType.DANGER) {
    bgColor = "var(--red)";
  } else if (type === NotificationType.SUCCESS) {
    bgColor = "var(--green)";
  }
  return css`
    background-color: ${bgColor};
  `;
};

const styles = {
  NotificationWrapper: (type: NotificationType): SerializedStyles => {
    return css`
      position: fixed;
      right: 0;
      bottom: -150px;
      margin: 2rem;
      border: 1px solid transparent;
      padding: 2rem;
      border-radius: 0.25rem;
      max-width: 25%;
      ${getBgColor(type)};
    `;
  }
};

export default styles;