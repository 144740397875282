import { css } from "@emotion/react";
import theme, { mediaQueries } from "../../theme";

const styles = {
  AboutUsWrapper: () => css`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  `,
  Title: css`
    color: white;
    text-align: center;
    margin-top: 2rem;

    ${mediaQueries.lg} {
      margin-bottom: 3rem;
    }
  `,
  WeOfferMore: css`
    text-align: center;
  `,
  MissionStatementWrapper: css`
    display: flex;
    justify-content: center;
    color: white;
    text-shadow: 1px 1px black;
    padding: 2rem;

    div {
      ${theme.mediaQueries.lg} {
        max-width: 75%;
      }
    }
  `,
  Link: css`
    color: #23ADB2 !important;
  `
};

export default styles;