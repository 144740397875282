import { Fragment } from "react";
import { useSwiper } from "swiper/react";
import theme from "../../../../theme";
import { Arrow } from "../../Arrow/Arrow";
import { ArrowDirection } from "../../Arrow/types";
import styles from "./styles";

export const ButtonPrevious = () => {
  const swiper = useSwiper();
  const { activeIndex } = swiper;

  return (
    <Fragment>
      {
        activeIndex !== 0 && (
          <Arrow 
            styling={styles.ButtonPrevious}
            onClick={() => swiper.slidePrev()}
            colour={theme.appTheme.fg}
            direction={ArrowDirection.LEFT} 
          />
        )
      }
    </Fragment>
  );
};