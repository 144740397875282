import { css } from "@emotion/react";
import { ITheme, mediaQueries } from "../../theme";

const styles = {
  TestimonialsWrapper: (t: ITheme) => {
    return css`
      color: ${t.colorAlt};

      ${mediaQueries.lg} {
        margin-top: 4rem;
      }
    `;
  }
};

export default styles;