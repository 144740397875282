import { SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";

export enum IButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  ALTERNATIVE = 'ALTERNATIVE'
}

export interface IButtonProps {
  btnType: IButtonType;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: Function;
  children?: ReactNode;
  styling?: SerializedStyles | Array<SerializedStyles>; 
  disabled?: boolean;
}
