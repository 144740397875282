// https://swiperjs.com/react
// Import Swiper React components
import { Swiper } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import { ReactElement, useState } from 'react';
import { ButtonNext } from './ButtonNext';
import { ButtonPrevious } from './ButtonPrevious';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import styles from './styles';

interface SlideStyleProps {
  children: Array<ReactElement> | ReactElement;
}

export const Carousel = ({
  children
}: SlideStyleProps) => {

  const [, setActiveIndex] = useState<number>();
  const autoplay = {
    delay: 2500,
    disableOnInteraction: false,
  };

  return (
    <Swiper
      css={styles.CarouselWrapper}
      modules={[Autoplay, Navigation]}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      autoplay={autoplay}
      speed={2500}
    >
      {children}
      <ButtonPrevious></ButtonPrevious>
      <ButtonNext></ButtonNext>
    </Swiper>
  );
};