import styles from "./styles";
import { IButtonProps } from "./types";

/**
  <Button type={IButtonType.PRIMARY} onClick={() => console.log('Hello World')}>Hello World</Button>
  <Button type={IButtonType.SECONDARY} onClick={() => console.log('Hello World')}>Hello World</Button>
  <Button type={IButtonType.TERTIARY} onClick={() => console.log('Hello World')}>Hello World</Button>
  <Button type={IButtonType.ALTERNATIVE} onClick={() => console.log('Hello World')}>Hello World</Button>  
*/
export const Button = ({
  btnType,
  type = "button",
  disabled = false,
  onClick,
  children,
  styling
}: IButtonProps) => {
  return (
    <button type={type} css={[styles.ButtonWrapper(btnType, disabled), styling]} onClick={() => onClick ? onClick() : null} disabled={disabled}>
      {children}
    </button>
  );
}
