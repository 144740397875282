import { css } from "@emotion/react";
import theme, { ITheme, mediaQueries } from "../../../theme";

const ourTeamPadding = '10rem';

const styles = {
  GetInContact: css`
    display: flex;
    flex-direction: column;
    width: 300px;
    
    img {
      border-radius: 0.25rem;
    }

    ${theme.mediaQueries.lg} {
      // max-width: ${theme.breakpoints.lg / 2}px;
      max-width: 350px;
    }
  `,
  OurTeam: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #fff;
    padding-bottom: 3rem;
    // height: calc(100vh - (${ourTeamPadding} + 150px));
    box-sizing: border-box;

    span {
      text-shadow: 1px 1px black;
    }

    ${mediaQueries.sm} {
      // height: calc(100vh - (${ourTeamPadding} + 150px));
    }

    ${mediaQueries.md} {
      // height: calc(100vh - (${ourTeamPadding} + 220px));
    }
    
    ${mediaQueries.lg} {
      // margin-top: 15rem;
    }
  `,
  Banner: css`
    padding: 4rem 2rem;
    background-color: white;
    width: 80%;
    opacity: 0.75;

    > span {
      color: black;
      text-shadow: none;
    }
  `,
  TouchBtn: css`
    margin: 1rem 0 0.75rem 0;
  `,
  Message: (t: ITheme) => {
    return css`
      text-align: center;
      color: ${t.colorAlt};

      p {
        max-width: 30rem;
      }

      ${theme.mediaQueries.lg} {
        text-align: left;
      }
    `;
  },
  GetInTouch: (theme: ITheme) => {
    return css`
      text-decoration: none;
      text-align:center;
      color: ${theme.colorAlt};
      font-size: 1.15rem;
      text-decoration: underline;
    `;
  }
};

export default styles;