import { css } from "@emotion/react";

export const animations = {
  slideInFromLeft: (
    delay: string = '0s',
    duration: string = '1.5s'
  ) => {
    return css`
      @keyframes slide-in-from-left {
        from {
          transform: translateX(-100vw);
        }
        to {
          ${delay ? 'opacity: 1;' : null}
          transform: translateX(0);
        }
      }
      opacity: ${delay ? '0;' : '1;'}
      animation-name: slide-in-from-left;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: forwards;
    `;
  },
  slideOutToLeft: (
    delay: string = '0s',
    duration: string = '1.5s',
    fillMode: string = 'forwards'
  ) => {
    return css`
      @keyframes slide-out-to-left {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100vw);
        }
      }
      animation-name: slide-out-to-left;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: ${fillMode};
    `;
  },
  slideInFromRight: (
    delay: string = '0s',
    duration: string = '1.5s'
  ) => {
    return css`
      @keyframes slide-in-from-right {
        from {
          transform: translateX(100vw);
        }
        to {
          ${delay ? 'opacity: 1;' : null}
          transform: translateX(0%);
        }
      }
      opacity: ${delay ? '0;' : '1;'}
      animation-name: slide-in-from-right;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: forwards;
    `;
  },
  slideOutToRight: (
    delay: string = '0s',
    duration: string = '1.5s',
    fillMode: string = 'forwards'
  ) => {
    return css`
      @keyframes slide-out-to-right {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(100vw);
        }
      }
      animation-name: slide-out-to-right;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: ${fillMode};
    `;
  },
  fadeIn: (
    delay: string = '0s',
    duration: string = '1.5s'
  ) => {
    return css`
      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      opacity: 0;
      animation-name: fade-in;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: forwards;
    `;
  },

  // TODO
  // Carousel slide animations
  slideOutLeftAndFadeIn: () => {
    return css`
      @keyframes slide-out-left-and-fade-in {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100vw);
        }
      }
      animation-name: slide-out-left-and-fade-in;
      animation-duration: 1.5s;
    `;
  },
  slideOutRightAndFadeIn: () => {},
  // When we want to combine animations we need to
  // seperate them with a comma
  
  /**
  bgColorFadeIn: (color: string) => css`
    @keyframes bg-color-fade-in {
      from {
        background-color: inherit;
      }
      to {
        background-color: ${color};
      }
    }
    animation: bg-color-fade-in 0.5s 0.5s forwards;
  `,
  */
  slideSidebarInFromRight: (initialRight: string | number, slideTo: string | number) => {
    return css`
      @keyframes slide-sidebar-in-from-right {
        from {
          right: ${initialRight};
        }
        to {
          right: ${slideTo};
        }
      }
      animation-name: slide-sidebar-in-from-right;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    `;
  },
  slideSidebarToTheRight: (from: string | number, to: string | number) => {
    return css`
      @keyframes slide-sidebar-to-the-right {
        from {
          right: ${from};
        }
        to {
          right: ${to};
        }
      }
      animation-name: slide-sidebar-to-the-right;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    `;
  },
  slideUpFromBottom: (
    delay: string = '0s',
    duration: string = '1s'
  ) => {
    return css`
      @keyframes slide-up-from-bottom {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-150px);
        }
      }
      animation-name: slide-up-from-bottom;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: forwards;
    `;
  },
  slideOutToBottom: (
    delay: string = '0s',
    duration: string = '1s'
  ) => {
    return css`
      @keyframes slide-out-to-bottom {
        from {
          transform: translateY(-150px);
        }
        to {
          transform: translateY(0);
        }
      }
      animation-name: slide-out-to-bottom;
      animation-duration: ${duration};
      animation-delay: ${delay};
      animation-fill-mode: forwards;
    `;
  },
};