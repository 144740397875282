import { SwiperSlide } from "swiper/react";
import { testimonials } from "../../../utils/testimonials";
import { animations } from "../../Shared/Animations";
import { Heading } from "../../Shared/Heading";
import { Size } from "../../Shared/Heading/types";
import { Carousel } from "../../Shared/Carousel";

export const TestimonialsCarousel = () => {

  const mouseOverSwiper = () => {
    console.log('mouseOverSwiper');
  };

  return (
    <Carousel>
      <SwiperSlide
        onMouseOver={mouseOverSwiper}
      >
        <div 
          key={testimonials[0].author}
          className="testimonials" 
          css={animations.slideInFromLeft()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[0].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[0].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[0].company}</Heading>
        </div>
        <br/>
        <div 
          key={testimonials[1].author}
          className="testimonials" 
          css={animations.slideInFromRight()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[1].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[1].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[1].company}</Heading>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div 
          key={testimonials[2].author}
          className="testimonials" 
          css={animations.slideInFromLeft()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[2].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[2].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[2].company}</Heading>
        </div>
        <br/>
        <div 
          key={testimonials[3].author}
          className="testimonials" 
          css={animations.slideInFromRight()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[3].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[3].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[3].company}</Heading>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div 
          key={testimonials[4].author}
          className="testimonials" 
          css={animations.slideInFromLeft()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[4].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[4].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[4].company}</Heading>
        </div>
        <br/>
        <div 
          key={testimonials[5].author}
          className="testimonials" 
          css={animations.slideInFromRight()}
        >
          <Heading size={Size.H4_SMALL}><q>{testimonials[5].testimonial}</q></Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[5].author},</Heading>
          <Heading size={Size.H5_SMALL}>{testimonials[5].company}</Heading>
        </div>
      </SwiperSlide>
    </Carousel>
  );
}