import { css } from "@emotion/react";
import theme, { ITheme } from "../../theme";

const styles = {
  TestimonialWrapper: (t: ITheme, pathname: string, i: number) => {
    return css`
      color: ${t.colorAlt};
      margin-bottom: 2rem;
      text-shadow: 1px 1px black;

      h4 {
        text-align: center;
        padding-right: 1rem;

        :nth-of-type(1) {
          margin: 1rem 0 0 0;
        }
      }

      ${theme.mediaQueries.md} {
        margin-bottom: 3rem;
        padding-bottom: 2rem;
        height: 350px;
        ${i % 2 === 1 ? `background-color: ${theme.colours.logo.green};` : ';'}

        h4 {
          text-align: right;
        }
      }

      ${theme.mediaQueries.lg} {
        margin: 2rem 0 4rem 0;

        ${pathname === '/success-stories'} {
          display: flex;
          justify-content: space-evenly;
          width: 100%;  

          > div {
            justify-content: center;
            width: 50%;
          }

          > div:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            justify-items: left;
            align-items: flex-start;

            order: ${i % 2 === 0 ? '1;' : '0;'}
            p {
              padding: 0;
            }
          }
        }
      }
    `;
  },
  TestimonialText: css`
    ${theme.mediaQueries.md} {
      justify-content: center;
      // quotes: '\201c' '\201d';

      // :before, :after {
      //   content: open-quote;
      // }
    }
  `,
  Testimonial: (pathname: string) => {
    return css`
      position: relative;
      padding: 0 1rem;
      margin: 0;
      font-size: 0.9rem;
      text-align: center;

      ${theme.mediaQueries.md} {
        text-align: right;
      }
      
      ${theme.mediaQueries.lg} {
        ${pathname === '/success-stories'} {
          text-align: center;
          max-width: 600px;
        }
      }
    `;
  },
  Quote: (side: string) => {
    return css`
      position: absolute;
      font-size: 4rem;
      ${side}: ${side === "top" ? "-40px" : "-40px"};
      ${side === "top" ? "left: 0" : "right: 0"};
    `;
  },
  Logo: (t: ITheme) => {
    return css`
      display: none;
      width: 20rem;
      max-width: 250px;
      cursor: pointer;
      
      ${theme.mediaQueries.lg} {
        display: flex;
        align-items: center;
        padding-left: 3rem;
        padding-top: 1rem;
      }
    `;
  },
  Divider: css`
    display: none;
    ${theme.mediaQueries.lg} {
      display: block;
      width: 20rem;
      color: white;
      margin-bottom: 4rem;
    }
  `,
};

export default styles;