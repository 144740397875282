import { useContext } from "react";
import { Link } from "react-router-dom";
import ThemeContext from "../../utils/ThemeContext";
import { IOptions } from "./types";
import sharedStyles from "../Shared/Styles";
import styles from "./styles";

export const Options = ({
  className,
  inSidebar,
  setOpenSidebar
}: IOptions) => {
  
  const options = ["About Us", "Success Stories", "Our Services", "Contact Us"];
  const { theme } = useContext(ThemeContext);
  
  return (
    <div css={styles.OptionsWrapper(theme, !!inSidebar)} className={className}>
      {options.map((option) => 
        <Link 
          key={option} 
          to={option.toLowerCase().replace(" ", "-")} 
          css={sharedStyles.Link(theme, inSidebar)} 
          onClick={() => {
            if (setOpenSidebar) {
              setOpenSidebar(false)
            }
          }}>
          {option}
        </Link>)
      }
    </div>
  );
};