import { Fragment, useEffect, useState } from "react";

import { Logo } from "../Logo";
import { Options } from "../Options";
import JMWLogoLight from "../../assets/logo-white.svg";
import { SocialMedia } from "../Shared/SocialMedia";
import { animations } from "../Shared/Animations";
import useWindowSize from "../../hooks/use-window-size";
import { breakpoints } from "../../theme";
import useScroll, { ScrollDirection } from "../../hooks/use-scroll";
import styles from "./styles";
import { useLocation } from "react-router-dom";

export const Navigation = () => {
  const scrolling = useScroll(100);
  const [navBg, setNavBg] = useState('');
  const { width } = useWindowSize();
  let location = useLocation();

  useEffect(() => {
    // TODO Set the title based on the pathname 
    // Chapter 3 https://backlinko.com/on-page-seo
    // document.title = location.pathname;

    // TODO Also need to fix our services error being thrown
  }, [location]);

  // Switch to an animations for the colour to fade in
  // Also - only activate it when we've scrolled, say, a couple of hundred
  // pixels

  // Need to switch this animation class to a function that can be shared
  if (scrolling === ScrollDirection.UP && navBg === '') {
    setNavBg('bg-color-fade-in ');
  }
  
  return (
    <div css={[styles.NavigationWrapper, animations.fadeIn('3.5s')]}>
      <div className={navBg} css={styles.NavContentWrapper}>
        <Logo src={JMWLogoLight} styling={animations.fadeIn()} alt="JMW Accountants" />
        {width >= breakpoints.md && (
          <Fragment>
            <Options />
            <SocialMedia />
          </Fragment>
        )}
      </div>
    </div>
  );
};