import { css } from "@emotion/react";
import { mediaQueries } from "../../../theme";

const styles = {
  CarouselWrapper: css`
    position: initial;
    text-shadow: 1px 1px black;

    ${mediaQueries.lg} {
      // background-color: orange;
    }
  `,
};

export default styles;