import { css } from "@emotion/react";
import { mediaQueries } from "../../theme";

const styles = {
  HomeWrapper: css`
    text-align: center;
  `,
  TestimonialsWrapper: css`
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 600px;
    padding: 4rem 1rem 1rem 1rem;

    ${mediaQueries.md} {
      // padding-bottom: 3rem;
    }
  `,
  CarouselWrapper: css`
    position: relative;
    width: 350px;
    
    span:nth-of-type(1) {
      margin-bottom: 2rem;
    }

    ${mediaQueries.md} {
      width: 550px;
    }

    ${mediaQueries.lg} {
      width: 750px;
    }

  `,
  WhatWeDoWrapper: css`
    background-color: var(--green);
    display: flex;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;

    ${mediaQueries.md} {
      padding: 3rem 4rem;
      div {
        max-width: 750px;
      }
    }

    ${mediaQueries.xLg} {
      height: 250px;
      justify-content: center;
    }
  `,
};

export default styles;