export enum Size {
  H1 = 'H1',
  H1_SMALL = 'H1_SMALL',
  H2 = 'H2',
  H2_SMALL = 'H2_SMALL',
  H3 = 'H3',
  H3_SMALL = 'H3_SMALL',
  H4 = 'H4',
  H4_SMALL = 'H4_SMALL',
  H5 = 'H5',
  H5_SMALL = 'H5_SMALL',
  H6 = 'H6',
  H6_SMALL = 'H6_SMALL'
}