// import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

// import appTheme from "../../theme";
// import ThemeContext from "../../utils/ThemeContext";
import styles from "./styles";
import { Tooltip } from '../Tooltip';
import { TooltipPosition } from '../Tooltip/types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface ISocialMediaProps {
  inSidebar?: boolean;
}

export const SocialMedia = ({
  inSidebar
} : ISocialMediaProps) => {
  // const { theme, setTheme } = useContext(ThemeContext);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div css={styles.SocialMediaWrapper(!!inSidebar)}>
      <div css={styles.ContactWrapper}>
        {/* jonathan.wallwin@jmwaccountants.com */}
        <Link to="/contact-us">
          <FontAwesomeIcon css={styles.Email} icon={faEnvelope} size="lg" />
        </Link>
        <span css={styles.TooltipContainer} onMouseEnter={() => setShowTooltip(!showTooltip)} onMouseLeave={() => setShowTooltip(false)} onClick={() => setShowTooltip(!showTooltip)}>
          <FontAwesomeIcon css={styles.PhoneNumber} icon={faPhoneSquare} size="lg" />
          {showTooltip && <Tooltip position={TooltipPosition.BOTTOM} message="028 92 528319" />}
        </span>
        <a href="https://www.facebook.com/people/JM-Wallwin-Chartered-Accountants/100065319290497/ " target="_blank" rel="noreferrer">
          <FontAwesomeIcon css={styles.Facebook} icon={faFacebook} size="lg" />
        </a>
      </div>
      {/* <div css={styles.ThemeToggleWrapper}>
        <button type="button" onClick={() => setTheme(appTheme.primary)} css={[styles.ThemeBtn, styles.TealBtn(appTheme.primary)]} />
        <button type="button" onClick={() => setTheme(appTheme.secondary)} css={[styles.ThemeBtn, styles.WhiteBtn(appTheme.secondary)]} />
      </div> */}
    </div>
  );
};