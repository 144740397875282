import NorthernExcavators from "../assets/northern-excavators.png";
import Lyons from "../assets/lyons-ni-logo.png";
import TPM from "../assets/tpm.svg";
import ElectricFire from "../assets/electric-fire.svg";
import JSNI from "../assets/jsni.png";
import Tweedies from "../assets/tweedies-bar.svg";
import { CSSProperties } from "react";

export interface ITestimonial {
  author: string;
  position: string;
  company: string;
  testimonial: string;
  logo: string;
  i?: number;
  bgStyles?: CSSProperties;
  link?: string;
}

export const testimonials: Array<ITestimonial> = [
  {
    author: 'Mr Clifford Lilburn',
    position: 'Company Director',
    company: 'Northern Excavators Limited',
    testimonial: `
      We would thoroughly recommend J.M. Wallwin Chartered Accountants. Jonathan has worked with our company for many years, conducting our yearly audit as an employee of another practice. Due to his ability proven over those years in his work ethic, knowledge, communication skills, cost cutting systems and practices which proved invaluable and increased our profit margins, we had no hesitation in moving our business to Jonathan on the launch of his own practice. Jonathan is now responsible for our year end accounts, corporation tax and monthly management accounts.
    `,
    logo: NorthernExcavators,
    link: "https://www.northernexcavators.com/"
  },
  {
    author: 'Mr Joseph Lyons',
    position: 'Company Director',
    company: 'Lyons N.I. Ltd',
    testimonial: `
      I would have no hesitation in recommending J.M. Wallwin Chartered Accountants.
      Jonathan provides us with a fantastic service that is always prompt, professional and personable.
      He explains things clearly, is incredibly knowledgeable and his advice and insights improve our business at every turn.
      We don’t simply see him as our accountant, he’s part of the team at Lyons NI. 
    `,
    logo: Lyons,
    bgStyles: { backgroundColor: "#3b3b3b" },
    link: "https://www.lyonsni.com/"
  },
  {
    author: 'Mrs Elaine Dowdall',
    position: 'Manager',
    company: 'TPM Credit Union',
    testimonial: `
      TPM Credit Union are delighted with the Internal Audit service provided by J.M. Wallwin Chartered Accountants.
      Jonathan provides us with the piece of mind that we are complying with the PRA regulations, visits are always completed by Jonathan allowing us to build up a rapport, and reporting is provided in a clear and concise manner.
      We would have no hesitation recommending Jonathan’s services to other Credit Unions.  
    `,
    logo: TPM,
    link: "https://tpmcreditunion.co.uk/"
  },
  {
    author: 'Mr Trevor Best',
    position: 'Company Director',
    company: 'Electric Fires NI Ltd',
    testimonial: `
      Jonathan continually goes above and beyond for us and we are more than happy with the service he provides.
      He is very proactive in dealing with any queries or issues we may have. 
      I would have no hesitation in recommending Jonathan! 
    `,
    logo: ElectricFire,
    link: "https://electricfiresni.co.uk/"
  },
  {
    author: 'Mr Michael Scott',
    position: 'Company Director',
    company: 'JSNI Ltd',
    testimonial: `
      We moved to J.M. Wallwin Chartered Accountants in 2021. During a difficult period with Covid-19, Jonathan’s experience and guidance has been essential to our business. We look forward to working with Jonathan for many years to come.
    `,
    logo: JSNI,
    link: "https://www.jsniltd.co.uk/"
  },
  {
    author: 'Mr Stephen Ryles',
    position: '',
    company: 'Tweedies Bar, Parkgate',
    testimonial: `
      I have known Jonathan on a personal level for a number years and professionally for nearly two. During that time, I have found Jonny to be courteous, diligent and with a work ethic second to none. I had been unhappy for some time with the poor service from my former accountants. I approachedJonny, explaining the protracted issues I was having with them and he agreed to take on my accounts. Since then, I haven’t looked back! Matters that had dragged on for years, he was able to finalise in months. His proactive approach and ability to highlight matters beneficial to my business and to me on a personal level were refreshing to say the least! My only regret is that I hadn’t contacted Jonny sooner. Would I recommend him? Most definitely! Are his fees worthy of the work he puts in? Yes! Does he think outside the box, put forward proposals and go the extra mile? Certainly! “No” and “I cannot help you” don’t figure in his vocabular
    `,
    logo: Tweedies,
    link: "https://www.tweediesgrasmere.com/tweedies-bar/"
  }
];