import styles from "./styles";
import { IArrowProps } from "./types";

export const Arrow = ({
  direction,
  colour,
  width,
  styling,
  onClick,
}: IArrowProps) => {
  return <i css={[styles.Arrow({ direction, colour, width }), styling]} onClick={() => onClick()}></i>
};