import { css } from "@emotion/react";
import { ITheme } from "../../theme";

const styles = {
  SuccessStoriesWrapper: (t: ITheme) => {
    return css`
      padding: 2rem 0 2rem 0;
      text-shadow: 1px 1px black;
      color: ${t.colorAlt};
      display: flex;
      flex-direction: column;
      // align-items: center;

      // > div {
      //   max-width: 992px;
      // }
      
      > div:nth-of-type(1) {
        padding-top: 2rem;
      }

      // The testimonial author
      h4 {
        text-align: center;
        margin-bottom: 1rem;
        
        :nth-of-type(1) {
          margin: 1rem 0 0 0;
        }
        :nth-of-type(2) {
          margin: 0 0 1rem 0;
        }
      }
    `; 
  },
  WhatPeopleSay: css`
    text-align: center;
    margin: 0 0 3rem 0 !important;
    padding-top: 2rem;
  `,
};

export default styles;