import React, { Fragment, RefObject } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { AboutUs } from "./components/AboutUs";
import { ContactUs } from "./components/ContactUs";
import { Home } from "./components/Home";
import { OurServices } from "./components/OurServices";
import { services } from "./components/OurServices/services";
import { Content } from "./components/Shared/Content";
import { SuccessStories } from "./components/Success-Stories";
import { RouteUrls } from "./urls";

interface IAppRoutes {
  aboutUsRef: RefObject<HTMLDivElement>;
  successStoriesRef: RefObject<HTMLDivElement>;
  ourServicesRef: RefObject<HTMLDivElement>;
  contactUsRef: RefObject<HTMLDivElement>;
  refs:  {
    [key: string]: RefObject<HTMLDivElement>
  };
  showTests: boolean;
}

export const AppRoutes = ({
  aboutUsRef,
  successStoriesRef,
  ourServicesRef,
  contactUsRef,
  refs,
  showTests,
}: IAppRoutes) => {
  return (
    <Routes>
      <Route path={RouteUrls.aboutUs} element={<AboutUs aboutUsRef={aboutUsRef} />} />
      <Route path={RouteUrls.successStories} element={<SuccessStories successStoriesRef={successStoriesRef} />} />
      <Route path={RouteUrls.ourServices} element={<OurServices ourServicesRef={ourServicesRef} />} />
      <Route path={RouteUrls.contactUs} element={<ContactUs contactUsRef={contactUsRef} />} />
      <Route path={RouteUrls.home} element={<Home refs={refs} showTests={showTests} />} />
    
      {services.map((s) => {
        return (
          <Route key={s.name} path={s.route} element={
            <Fragment>
              <Content title={s.name} text={(s.description) as string} bgColor={s.bgColour} image={s.image}>
              </Content>
            </Fragment>
          }/>
        );
      })}
    </Routes>
  );
}