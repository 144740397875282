import { Fragment, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Wallwin from "./assets/me-removebg.png";
import Bricks from "./assets/bricks.jpg";
import PictureWall from "./assets/picture-wall.jpg";
import Stones from "./assets/stones.jpg";
import Time from "./assets/time.jpg";
import Cliff from "./assets/cliff.jpg";

import { ThemeContextProvider } from './utils/ThemeContext';
import useScroll, { ScrollDirection } from "./hooks/use-scroll";

import { Footer } from "./components/Footer";
import { Intro } from "./components/Shared/Intro";
import { Navigation } from "./components/Navigation";
import { animations } from "./components/Shared/Animations";
import { Sidebar } from "./components/Shared/Sidebar";
import { AppRoutes } from "./Routes";
import styles from "./styles";

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const scrolling = useScroll();
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const successStoriesRef = useRef<HTMLDivElement>(null);
  const ourServicesRef = useRef<HTMLDivElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);

  const [showTests, setShowTests] = useState<boolean>();
  const [openSidebar, setOpenSidebar] = useState<boolean | null>(null);

  useEffect(() => {
    const imgs = [
      Wallwin,
      Bricks,
      PictureWall,
      Stones,
      Time,
      Cliff
    ];
    cacheImgs(imgs);
  }, []);

  const cacheImgs = async (imgArry: Array<string>) => {
    const promises = await imgArry.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve('');
        img.onerror = () => reject();
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
  }

  // Note: only works on screen sizes larger than mobile
  // const testimonialsInViewPort = useIntersection(refTestimonials, '0px');
  
  // const titleSize = width > breakpoints.md ? Size.H2 : Size.H4;
  // const subTitleSize = width > breakpoints.md ? Size.H2_SMALL : Size.H4_SMALL;

  if (scrolling === ScrollDirection.DOWN && !showTests) {
    setShowTests(true);
  }

  const refs = {
    testimonialsRef,
    aboutUsRef,
    successStoriesRef,
    ourServicesRef,
    contactUsRef
  };

  return (
    <Fragment>
      {!isLoading && (
        <Fragment>
          <ThemeContextProvider>
            <Router>
              <Navigation />
              <FontAwesomeIcon css={[animations.fadeIn('3.5s') ,styles.Menu]} icon={faBars} size="2x" onClick={() => setOpenSidebar(!openSidebar)} />
              <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
              <Intro 
                refs={refs}
                setShowTests={setShowTests}
              />
              <AppRoutes 
                aboutUsRef={aboutUsRef}
                successStoriesRef={successStoriesRef}
                ourServicesRef={ourServicesRef}
                contactUsRef={contactUsRef}
                refs={refs}
                showTests
              />
            </Router>
            <Footer />
          </ThemeContextProvider>
        </Fragment>
      )}   
    </Fragment>
  );
}

export default App;
