import { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ITestimonial } from "../../utils/testimonials";
import ThemeContext from "../../utils/ThemeContext";
import { Heading } from "../Shared/Heading";
import { Size } from "../Shared/Heading/types";
import styles from "./styles";

export const Testimonial = ({
  author,
  company,
  position,
  testimonial,
  logo,
  bgStyles,
  i,
  link
}: ITestimonial) => {
  const { theme } = useContext(ThemeContext);
  const { pathname } = useLocation();

  const handleClick = (link?: string) => {
    if (link) {
      window.open(link);
    }
  };

  return (
    <Fragment>
      <div css={styles.TestimonialWrapper(theme, pathname, i ? i : 0)}>
        {/* Add quotes */}
        <div css={styles.TestimonialText}>
          <Heading size={Size.H5}>
            {/* <q>{testimonial}</q> */}
            {testimonial}
          </Heading>
          <Heading size={Size.H5}>{author}</Heading> 
          <Heading size={Size.H5}>{company}</Heading> 
        </div>
        {pathname === '/success-stories' && <div css={styles.Logo(theme)}>
          <img alt={`${logo} link`} src={logo} style={ theme.id === 'secondary' ? bgStyles : {} } onClick={() => handleClick(link)} />
        </div>}
      </div>
    </Fragment>
  );
};
