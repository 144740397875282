import { css } from "@emotion/react";
import theme, { ITheme, mediaQueries } from "../../../theme";

const styles = {
  IntroContentWrapper: (fgImg: string, bgImg: string) => css`
    height: calc(100vh - 152px);
    text-align: center;
    padding-top: 152px;
    // 152px is the height of the navigation component
    background-image: url(${bgImg});
    background-size: cover;
    background-repeat: unset;
    filter: opacity(75%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    :after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${fgImg});
      background-repeat: no-repeat;
      background-position: 50% 125px;
      background-size: contain;
      z-index: -1;
      display: none;

      ${mediaQueries.md} {
        display: initial;
      }
    }
  `,
  Link: (t: ITheme, inSidebar?: boolean) => {
    return css`
      cursor: pointer;
      text-decoration: none;
      text-shadow: 1px 1px black;
      color: ${t.color};
      font-weight: 800;
      cursor: pointer;
      ${inSidebar ? '' : 'max-width: 4rem;'}
      text-align: center;
      // padding: 1rem 0.5rem;
      border-radius: 0.25rem;

      :hover {
        // color: white;
        color: var(--green);
        // background-color: ${theme.appTheme.fg};
      }

      ${theme.mediaQueries.sm} {
        max-width: initial;
      }
    `
  },
  TextShadow: (color: string) => css`
    text-shadow: 1px 1px ${color === 'white' ? 'black' : 'white'};
  `
};

export default styles;
