import { css } from "@emotion/react";
import { mediaQueries } from "../../../theme";
import { IButtonType } from "./types";

const primary = css`
  background-color: var(--green);
  color: var(--white);  
  :hover {
    background-color: var(--orange);
  }
`;

const secondary = css`
  background-color: var(--blue);
  color: var(--white);
  border: 1px solid var(--white);
  :hover {
    background-color: var(--green);
  }
`;

const tertiary = css`
  background-color: var(--orange);
  color: var(--white);
  :hover {
    background-color: var(--light-blue);
  }
`;

const alternative = css`
  background-color: var(--light-blue);
  color: var(--white);
  :hover {
    background-color: var(--orange);
  }
`;

const disabled = css`
  opacity: 0.75;
`;

const styles = {
  ButtonWrapper: (type: IButtonType, btnDisabled: boolean) => {
    let btnStyle = primary;

    switch(type) {
      case IButtonType.PRIMARY: btnStyle = primary; break;
      case IButtonType.SECONDARY: btnStyle = secondary; break;
      case IButtonType.TERTIARY: btnStyle = tertiary; break;
      case IButtonType.ALTERNATIVE: btnStyle = alternative; break;
    }

    if (btnDisabled) {
      btnStyle = disabled;
    }

    return css`
      cursor: pointer;
      padding: 1rem 2rem;
      border-radius: 1.5rem;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      ${btnStyle};

      ${mediaQueries.lg} {
        width: 250px;
      }
    `;
  }
};

export default styles;