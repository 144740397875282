import { css } from "@emotion/react";

const styles = {
  FooterWrapper: css`
    color: var(--white);
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 2rem 0;
  `,
};
export default styles;