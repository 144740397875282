// Images
import { animations } from "../Shared/Animations";
import { Content } from "../Shared/Content";
import { Heading } from "../Shared/Heading";
import { Size } from "../Shared/Heading/types";
import { IRefTestimonialsProps } from "../Shared/Types";
import { TestimonialsCarousel } from "./components/TestimonialsCarousel";

// Styling
import styles from "./styles";

export const Home = ({
  refs,
  showTests
}: IRefTestimonialsProps) => {
  const { testimonialsRef } = refs;
  const ourServicesText = [
    "We specialise in helping small and medium sized businesses like yours. We offer a comprehensive range of services (can this services word be a link that leads to the bottom of the page) and year round advice that few can match.",
    "Together we design a  tailored package based on your business needs and budget. What business issues are you facing? How often do you want to see us and when?",
    "As part of your team we develop a working relationship where we can offer meaningful advice to drive your business forward to meet your goals and objectives."
  ]

  return (
    <div css={styles.HomeWrapper}>
      {showTests && (
        <div css={[animations.fadeIn('1s'), styles.HomeWrapper]}>
          <div ref={testimonialsRef} css={styles.TestimonialsWrapper}>
            <div css={styles.CarouselWrapper}>
            <Heading size={Size.H4}>Our Clients</Heading>
              <TestimonialsCarousel />
            </div>
          </div>

          {/* What we do */}
          <Content
            title="Our Services"
            text={ourServicesText}
            bgColor="var(--green)"
          >
          </Content>
        </div>
      )}
    </div>
  );
};

