import { Button } from "../Button";

import Wallwin from "../../../assets/me-removebg.png";
import Bricks from "../../../assets/bricks.jpg";
import PictureWall from "../../../assets/picture-wall.jpg";
import Stones from "../../../assets/stones.jpg";
import Time from "../../../assets/time.jpg";
import Cliff from "../../../assets/cliff.jpg";

import { IButtonType } from "../Button/types";
import { RouteUrls } from "../../../urls";
import { animations } from "../Animations";
import { IPageIntros, IIntro } from "./types";
import styles from "./styles";

export const PageIntros = (navigate: (route: string) => void): IPageIntros => {
  return {
    home: {
      id: 1,
      title: 'Make Us Part of Your Team',
      subTitle: 'A one to one service with a Chartered Accountant',
      fgImg: Wallwin,
      bgImg: Bricks,
      arrow: true,
      btn: <Button btnType={IButtonType.PRIMARY} onClick={() => navigate(RouteUrls.contactUs)} styling={[animations.fadeIn('3s'), styles.TouchBtn]}>Get in touch</Button>
    },
    aboutUs: {
      id: 2,
      title: 'Make Us Part of Your Team',
      subTitle: 'Tired of not getting the help and advice you need?',
      fgImg: '',
      bgImg: Cliff,
      arrow: true,
      btn: <Button btnType={IButtonType.PRIMARY} onClick={() => navigate(RouteUrls.contactUs)} styling={[animations.fadeIn('3s'), styles.TouchBtn]}>Get in touch</Button>
    },
    successStories: {
      id: 3,
      title: 'Success Stories',
      fgImg: '',
      bgImg: PictureWall,
      subTitle: 'What people say...',
      arrow: false
    },
    ourServices: {
      id: 4,
      title: 'Year round advice',
      subTitle: 'Get ahead of the game',
      fgImg: '',
      bgImg: Time,
      inBanner: false,
      arrow: false
    },
    contactUs: {
      id: 5,
      title: 'Contact Us',
      subTitle: 'Take the next step',
      fgImg: '',
      bgImg: Stones,
      inBanner: false,
      arrow: false
    },
  };
}  

export const setPageIntro = (
  pathname: string, 
  pageIntros: IPageIntros, 
  id: number,
  setIntro: (intro: IIntro) => void
) => {
  const { home, aboutUs, successStories, ourServices, contactUs } = pageIntros;
  
  switch (pathname) {
    case RouteUrls.home: 
      if (id !== home.id) { setIntro(home); }
    break;
    case RouteUrls.aboutUs: 
      if (id !== aboutUs.id) { setIntro(aboutUs); }
    break;
    case RouteUrls.successStories: 
      if (id !== successStories.id) { setIntro(successStories); }
    break;
    case RouteUrls.ourServices: 
      if (id !== ourServices.id) { setIntro(ourServices); }
    break;
    case RouteUrls.contactUs: 
      if (id !== contactUs.id) { setIntro(contactUs); }
    break;
  }
};