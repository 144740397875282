import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Options } from "../../Options";
import { animations } from "../Animations";
import { SocialMedia } from "../SocialMedia";
import styles from "./styles";

interface ISidebarProps {
  openSidebar: boolean | null;
  setOpenSidebar: (openSidebar: boolean) => void;
}

export const Sidebar = ({
  openSidebar,
  setOpenSidebar
}: ISidebarProps) => {
  return (
    <div 
      css={[
        styles.SidebarWrapper, 
        openSidebar === true ? animations.slideSidebarInFromRight(styles.right, 0) : null,
        openSidebar === false ? animations.slideSidebarToTheRight(0, `-${styles.right}%`) : null
      ]
    }>
      <div css={styles.SidebarContent}>
        <FontAwesomeIcon css={styles.CloseBtn} icon={faClose} size="2x" onClick={() => setOpenSidebar(!openSidebar)} />
        <Options inSidebar={true} setOpenSidebar={setOpenSidebar} />
        <SocialMedia inSidebar={true} />
      </div>
    </div>
  );
};