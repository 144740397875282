import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { animations } from "../Animations";
import { Heading } from "../Heading";
import { Arrow } from "../Arrow";
import { ArrowDirection } from "../Arrow/types";
import { Size } from "../Heading/types";
import theme from "../../../theme";
import { IRefTestimonialsProps } from "../Types";
import sharedStyles from "../Styles";
import styles from "./styles";
import { RouteUrls } from "../../../urls";
import { IIntro } from "./types";
import { PageIntros, setPageIntro } from "./page-intros";

export const Intro = ({
  refs,
  setShowTests,
}: IRefTestimonialsProps) => {

  const { 
    testimonialsRef,
    aboutUsRef,
    successStoriesRef,
    ourServicesRef,
    contactUsRef
  } = refs;
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageIntros = PageIntros(navigate);
  const [intro, setIntro] = useState<IIntro>(pageIntros.home);

  useEffect(() => {
    const { id } = intro;   
    setPageIntro(pathname, pageIntros, id, setIntro);
  }, [pathname, intro, pageIntros])

  // TODO Make padding uniform
  // TODO Move all shared styles into Shared folder (including text-shadow effect)
  // TODO Determine if the CarouselWrapper (in Home & Carousel component) is needed

  // TODO Tidy up
  const scrollDown = () => {
    if (pathname === '/') {
      testimonialsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (setShowTests) { setShowTests(true); }
    } else if (pathname === RouteUrls.successStories) {
      successStoriesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (pathname === RouteUrls.ourServices) {
      ourServicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (pathname === RouteUrls.aboutUs) {
      aboutUsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (pathname === RouteUrls.contactUs) {
      contactUsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div css={sharedStyles.IntroContentWrapper(intro.fgImg, intro.bgImg)}>
      <div css={[styles.OurTeam]}>
        {intro.inBanner && (
          <div css={[animations.fadeIn(), styles.Banner]}>
            <Heading styling={animations.slideInFromLeft('1s')} size={Size.H1}>{intro.title}</Heading>
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H2_SMALL}>{intro.subTitle}</Heading>
          </div>
        )}
        {!intro.inBanner && (
          <Fragment>
            <Heading styling={animations.slideInFromLeft('1s')} size={Size.H1}>{intro.title}</Heading>
            <Heading styling={animations.slideInFromRight('1s')} size={Size.H2_SMALL}>{intro.subTitle}</Heading>
          </Fragment>
        )}
        {intro.btn}
        {intro.arrow && (
          <Arrow 
            onClick={() => {
              // Note - we call this twice
              // as the first time will make the div
              // appear and the second will move the testimonials
              // into place
              scrollDown();
              setTimeout(() => scrollDown(), 0);
            }}
            direction={ArrowDirection.DOWN} 
            colour={theme.appTheme.bg} 
            styling={animations.fadeIn('3s')}
          />
        )}
      </div>
    </div>
  );
};