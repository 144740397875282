import { css } from "@emotion/react";
import theme from "../../../theme";

const styles = {
  PanelWrapper: css`
    display: flex;
    flex-direction: column;
    color: white;
    text-shadow: 1px 1px black;
    just-content: center;
  `,
  Title: css`
    text-align: center;
  `,
  PanelContent: css`
    display: flex;
  `,
  LeftPanel: css`
    ${theme.mediaQueries.lg} {
      width: 50%;
    }
  `,
  RightPanel: css`
    padding: 2rem;
    ${theme.mediaQueries.lg} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding-top: 0;

      span {
        margin-bottom: 1rem !important;
      }
    }
  `,
};

export default styles;