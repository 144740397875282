import theme from "../../theme";
import { IService, IServicePanel } from "./types";

import NeedHelp from "../../assets/need-help.jpg";
import { faCashRegister, faCloudArrowUp, faFlask, faHandsHolding, faPenNib, faReceipt, faRulerHorizontal, faStairs, faTeletype, faTowerBroadcast } from "@fortawesome/free-solid-svg-icons";

const ServiceDescription = ({
  title,
  services
}: IServicePanel) => {
  return (
    <div>
      <p>{title}</p>
      <ul>
        {services.map((s, i) => {
          return (
            <li key={i}>{s}</li>
          );
        })}
      </ul>
    </div>
  );
};

export const services: Array<IService> = [
  { 
    name: 'Accountancy',
    icon: faReceipt, 
    route:  '/accountancy',
    description: [
      'We offer be-spoke accountancy services for Groups of Companies, Limited Companies, Partnerships, Sole Traders, Charities, Clubs and Credit Unions.',
      'As part of our service we can offer ongoing support and advice, giving you the piece of mind your accounts and returns are in order.',
      <ServiceDescription
        title="Our accounting and compliance services include"
        services={[
          'Preparation of annual accounts',
          'Preparation of monthly, quarterly or bi-annual accounts',
          'Preparation of cashflow forecasts',
          'Advice on record keeping and software',
        ]}
      />
    ],
    bgColour: theme.colours.logo.blue,
    image: NeedHelp
  },
  { 
    name: 'Auditing', 
    icon: faPenNib, 
    route: '/auditing',
    description: [
      'As registered Auditors we can preform your external audit to ensure your financial statements are fully compliant with statutory requirements.',
      'Providing feedback on internal control deficiencies and recommendations for improvements.',
      <ServiceDescription
        title="Our audit services include:"
        services={[
          'Statutory audits',
          'Internal audits',
          'Credit Union audits',
          'Charity audits and independent examinations',
          'Club audits and independent examinations.',
        ]}
      />
    ]
  },
  { 
    name: 'Tax Compliance', 
    icon: faHandsHolding, 
    route: '/tax-compliance',
    description: [
      'Tax regulations are constantly evolving and can be an administrative burden for both Companies and Individuals, we can help to ensure you have the piece of mind that your returns are compliant and prepared in a timely manner.',
      <ServiceDescription
        title="Our tax compliance services include:"
        services={[
          'Preparation of Corporation tax returns',
          'Preparation of Self Assessment tax returns',
          'Preparation of VAT returns',
          'Preparation of payroll, auto-enrolment and PAYE returns',
          'Preparation of CIS tax returns',
          'Preparation of Capital Gains tax returns',
          'Assistance with HMRC tax investigations.',
        ]}
      />
    ]
  },
  { 
    name: 'Tax Planning', 
    icon: faRulerHorizontal, 
    route: '/tax-planning',
    description: [
      'Prior tax planning is essential for ensuring tax liabilities are minimised whilst ensuring tax affairs are still compliant with regulations.',
      'We undertake annual continued professional development courses to ensure we are always up to date with tax changes and incentives.',
      <ServiceDescription
        title="Our tax planning services include:"
        services={[
          'Determining the most tax-effective structure for your business',
          'Capital allowance reviews and planning',
          'Pension planning',
          'Capital gains tax planning',
          'Remuneration strategies',
          'Succession planning',
          'Property tax planning.',
        ]}
      />
    ]
  },
  { 
    name: 'Business Start Up', 
    icon: faStairs, 
    route:  '/business-start-up',
    description: [
      'Setting up a business can be dauting experience, we can help you cut through the red tape ensuring you are compliant and ready to do what you do best.',
      <ServiceDescription
        title="Our business start up advice includes:"
        services={[
          'The most suitable structure for your business – sole trader, partnership or Limited Company',
          'Ensuring you are aware of your filing requirements for your chosen structure',
          'Preparation of business plans, projections and budgets',
          'Assessing working capital requirements and sources of finance',
          'Completing any registration requirements with Companies House and HMRC',
          'Advice on a recording system and software options.', 
        ]}
      />   
    ]
  },
  { 
    name: 'Cloud Accounting & Bookkeeping', 
    icon: faCloudArrowUp, 
    route: '/cloud-accounting',
    description: [
      'We work alongside various software providers including Xero, Quickbooks, Sage, MTA and Ibcos.',
      <ServiceDescription
        title="Our cloud accounting services & bookkeeping services include:"
        services={[
          'Set up and training of various software packages',
          'Monthly or quarterly bank reconciliations',
          'Monthly or quarterly debtors reconciliations',
          'Monthly or quarterly creditors reconciliations',
          'Monthly or quarterly management accounts.',
        ]}
      />
    ]
  },
  { 
    name: 'Payroll', 
    icon: faCashRegister, 
    route: '/payroll',
    description: [
      'Payroll can be a laborious and complicated task. We can help to relief the burden ensuring your staff members and business are fully compliant.',
      <ServiceDescription
        title="Our payroll service includes:"
        services={[
          'Completion of statutory forms, including year end returns, to issue to your employees and HMRC',
          'Customised payslips',
          'Auto-enrolment and pension submissions',
          'Construction industry scheme returns',
          'Administration of bonuses, termination payments and incentive schemes.',
        ]}
      />
    ]
  },
  { 
    name: 'VAT', 
    icon: faTowerBroadcast, 
    route: '/vat',
    description: [
      'Making tax digital for VAT is now a requirement for all VAT registered businesses.',
      <ServiceDescription
        title="Our VAT service includes:"
        services={[
          'Advice on registration requirements for VAT',
          'Advice on record keeping requirements for VAT',
          'Preparation and submission of VAT returns using our VAT compliant software.',
        ]}
      />
    ],
  },
  { 
    name: 'Company Secretarial', 
    icon: faTeletype, 
    route: '/company-secretarial',
    description: [
      'The Companies Act provides a strict set of rules to submit documents in line with strict deadlines.',
      'We can provide piece of mind all your compliance needs are being met.',
      <ServiceDescription
        title="Our Company secretarial services include:"
        services={[
          'Preparation and filing of statutory returns',
          'Company formation',
          'Preparation of board minutes and resolutions',
          'Preparation of dividend vouchers',
          'Preparation of stock transfer documents.',
        ]}
      />
    ]
  },
  { 
    name: 'Consultancy work', 
    icon: faFlask, 
    route: '/consultancy-work',
    description: [
      'We specialise in providing one of consultancy services for a pre agreed fixed fee.',
      <ServiceDescription
        title="Our consultancy services include:"
        services={[
          'Company valuations',
          'Preparation of your Company for sale',
          'Company health checks',
          'Systems reviews',
          'Forensic accounting',
          'Preparation of business plans',
          'Cashflow forecasts',
          'Pension calculations',
        ]}
      />
    ]
  },
];