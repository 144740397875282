import { css } from "@emotion/react";
import theme from "../../theme";

const styles = {
  NavigationWrapper: css`
    position: fixed;
    width: 100%;
    height: 120px;
    // background-color: ${theme.appTheme.bg};
    display: flex;
    z-index: 2;
    top: 0;
  `,
  NavContentWrapper: css`
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
  `,
  LogoWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 75px;
  `
};

export default styles;