import { css } from "@emotion/react";

const right = 95;

const styles = {
  SidebarWrapper: css`
    position: fixed;
    right: -${right}%;
    width: ${right}vw;
    height: 100vh;
    background-color: var(--blue);
    z-index: 3;
    border-left: 1px solid white;
  `,
  right,
  SidebarContent: css`
    position: relative;
    height: 100vh;
    
    > div:nth-of-type(1) {
      flex-direction: column;
      
      a {
        margin: 2rem;
      }
    }
  `,
  CloseBtn: css`
    position: absolute;
    right: 2rem;
    top: 2.5rem;
    color: var(--white);
    cursor: pointer;

    // TODO All buttons and font awesome icons
    // share the same styling so it needs lifted
    // into the Shared folder
    :hover {
      color: var(--green);
    }
  `,
};

export default styles;