import { SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";
import styles from "./styles";
import { Size } from "./types";

interface IHeadingProps {
  size?: Size;
  styling?: SerializedStyles | Array<SerializedStyles>; 
  children: ReactNode;
  center?: boolean;
}

export const Heading = ({
  size = Size.H5,
  styling,
  center,
  children
}: IHeadingProps) => {
  if (size === Size.H1 || size === Size.H1_SMALL) {
    return <h1 css={[styles.Heading(size, center), styling]}>{children}</h1>
  } else if (size === Size.H2 || size === Size.H2_SMALL) {
    return <h2 css={[styles.Heading(size, center), styling]}>{children}</h2>
  } else if (size === Size.H3 || size === Size.H3_SMALL) {
    return <h3 css={[styles.Heading(size, center), styling]}>{children}</h3>
  } else if (size === Size.H4 || size === Size.H4_SMALL) {
    return <h4 css={[styles.Heading(size, center), styling]}>{children}</h4>
  } else if (size === Size.H5 || size === Size.H5_SMALL) {
    return <h5 css={[styles.Heading(size, center), styling]}>{children}</h5>
  } else {
    return <h6 css={[styles.Heading(size, center), styling]}>{children}</h6>
  }
};