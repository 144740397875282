import React, { useState, useEffect } from 'react'
import styles from '../styles';
import appTheme, { ITheme } from '../theme';

interface IThemeContextProps {
  children: React.ReactChild | Array<React.ReactChild>;
}

interface IThemeContext {
  theme: ITheme;
  setTheme: (t: ITheme) => void;
}

const ThemeContext = React.createContext<IThemeContext>({
  theme: appTheme.primary,
  setTheme: (theme: ITheme) => {},
});

export const ThemeContextProvider = ({
  children,
}: IThemeContextProps) => {

  const [theme, setTheme] = useState<ITheme>(appTheme.primary);

  useEffect(() => {
    document.documentElement.style.setProperty('--bg-color', theme.bg);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }} css={styles.App}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;