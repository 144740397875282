import styles from "./styles";
import { ITooltipProps } from "./types";

export const Tooltip = ({
  message,
  position
}: ITooltipProps) => {
  return (
    <div css={styles.TooltipWrapper}>
      {message}
    </div>
  );
};