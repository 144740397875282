import { ReactNode } from "react";

export enum NotificationType {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  DANGER = "DANGER"
};

export interface NotificationProps {
  kind: NotificationType;
  title?: string;
  children: ReactNode;
};