import { animations } from "../Animations";
import styles from "./styles";
import { NotificationProps } from "./types";

export const Notification = ({
  kind,
  children
}: NotificationProps) => {
  return (
    <div css={[styles.NotificationWrapper(kind), animations.slideUpFromBottom('0s')]}>
      {children}
    </div>
  );
};