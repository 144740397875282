import { css } from "@emotion/react";
import { mediaQueries } from "./theme";

const styles = {
  App: css`
    text-align: center;
  `,
  Menu: css`
    position: fixed;
    color: white;
    cursor: pointer;
    right: 2rem;
    top: 2.5rem;
    z-index: 3;

    :hover {
      color: #23ADB2;
    }

    ${mediaQueries.md} {
      display: none;
    }
  `,
};

export default styles;