import { css, SerializedStyles } from "@emotion/react";
import { mediaQueries } from "../../../theme";
import { Size } from "./types";

const setTextSize = (size: Size): SerializedStyles => {
  // H5 is standard size
  let fontSize = '24px';

  switch (size) {
    case Size.H1: fontSize = '48px'; break;
    case Size.H1_SMALL: fontSize = '44px'; break;
    case Size.H2: fontSize = '40px'; break;
    case Size.H2_SMALL: fontSize = '36px'; break;
    case Size.H3: fontSize = '32px'; break;
    case Size.H3_SMALL: fontSize = '28px'; break;
    case Size.H4: fontSize = '24px'; break;
    case Size.H4_SMALL: fontSize = '20px'; break;
    case Size.H5: fontSize = '16px'; break;
    case Size.H5_SMALL: fontSize = '12px'; break;
    case Size.H6: fontSize = '8px'; break;
    case Size.H6_SMALL: fontSize = '4px'; break;
  }
  return css`font-size: ${fontSize};`;
};

const styles = {
  Heading: (size: Size, center?: boolean) => {
    return css`
      display: block;
      position: relative;
      font-weight: lighter;
      ${setTextSize(size)}
      font-family: 'Fira Sans', sans-serif;
      margin-bottom: 0.5rem;
      ${center ? 'text-align: center;' : ';'}

      // Quotation marks
      // q {
      //   :before, :after {
      //     position: absolute;
      //     font-size: 3rem;
      //   }
      //   :before {
      //     left: -25px;
      //     top: -20px;
      //   }
      //   :after {
      //     top: calc(100% - 2rem);
      //     right: 0;
      //   }
      // }

      ${mediaQueries.lg} {
        :nth-of-type(2) {
          margin-bottom: 0;
        }
      }
    `;
  }
};

export default styles;