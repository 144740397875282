import { Fragment } from "react";
import { useSwiper } from "swiper/react";
import theme from "../../../../theme";
import { Arrow } from "../../Arrow";
import { ArrowDirection } from "../../Arrow/types";
import styles from "./styles";

export const ButtonNext = () => {
  const swiper = useSwiper();
  const { activeIndex } = swiper;

  // 2 needs to be replaced with the index of the last array element
  // Remember that we split the testimonials into pairs
  // 2 > 2 > 2
  return (
    <Fragment>
      {
        activeIndex !== 2 && (
          <Arrow 
            styling={styles.ButtonNext}
            onClick={() => swiper.slideNext()}
            colour={theme.appTheme.fg}
            direction={ArrowDirection.RIGHT} 
          />
        )
      }
    </Fragment>
  );
};