import { css } from "@emotion/react";
import SharedStyles from "../Styles";
import theme from "../../../theme";

const styles = {
  ContentWrapper: (bgColor: string, textColor: string = 'white', image: string) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: ${bgColor};
    color: ${textColor ? textColor : 'white'};
    position: relative;
    ${SharedStyles.TextShadow(textColor)}
    
    // TODO May not need this 
    :after {
      content: '';
      background-image: url(${image});
      background-repeat: none;
      right: 0;
      bottom: 0;
      position: absolute;
      // width: 100px;
      // height: 100px;
    }

    > div {
      ${theme.mediaQueries.md} {
        max-width: ${theme.maxWidths.md};
      }
      ${theme.mediaQueries.lg} {
        
      }
    }
  `,
  Title: css`
    margin-bottom: 1.5rem;
  `,
};

export default styles;