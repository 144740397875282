import { SerializedStyles } from "@emotion/react";

export enum ArrowDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export interface IArrowProps {
  direction: ArrowDirection;
  onClick: Function;
  width?: number;
  colour?: string;
  styling?: SerializedStyles | Array<SerializedStyles>; 
}