import { css } from "@emotion/react";

const styles = {
  TooltipWrapper: () => {
    return css`
      position: absolute;
      border: 1px solid var(--blue);
      padding: 0.25rem 1rem;
      left: -50%;
      top: 1.75rem;
      background-color: var(--green);
      border-radius: 0.25rem;

      :hover {
        color: white;
      }
      
      // Arrow, position determined by passed direction
      :before {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 7.5px solid var(--green);
        position: absolute;
        top: -7px;
        // left determined by left position of parent minus half padding left
        left: calc(50% - 0.5rem);
      }
    `;
  }
};

export default styles;