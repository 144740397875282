import { useEffect, useState } from "react";

export enum ScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN'
}

// Returns true, false if scrolling
export default function useScroll(additional: number = 0, direction: ScrollDirection = ScrollDirection.DOWN) {

  const getPosition = () => window.scrollY || document.documentElement.scrollTop;
  const [startPosition] = useState<number>(getPosition() + additional);
  const [scrolling, setScrolling] = useState<ScrollDirection>();
  
  useEffect(() => {
    const handleScroll = () => {
      const direction = startPosition > getPosition() ? ScrollDirection.UP : ScrollDirection.DOWN;
      setScrolling(direction);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [startPosition]);

  return scrolling;
}