import axios from "axios";
import { FormEvent, RefObject, useContext, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import ThemeContext from "../../utils/ThemeContext";
import styles from "./styles";
import { Button } from "../Shared/Button";
import { IButtonType } from "../Shared/Button/types";
import { Size } from "../Shared/Heading/types";
import { Heading } from "../Shared/Heading";
import { Notification } from "../Shared/Notification";
import { NotificationType } from "../Shared/Notification/types";

interface IContactUsProps {
  contactUsRef: RefObject<HTMLDivElement>;
}

export const ContactUs = ({
  contactUsRef,
}: IContactUsProps) => {
  const { theme } = useContext(ThemeContext);

  // Move this url to an environment variable
  const url = window.location.href.includes("localhost") ? "http://localhost:8080" : "https://jmwa-lsi6vcmerq-nw.a.run.app";

  const [inProgress, setInProgess] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>();
  const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.SUCCESS);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

  const emailRef = useRef<HTMLInputElement>(null);
  const enquiryRef = useRef<HTMLTextAreaElement>(null);

  const handleFormChange = (event: FormEvent<HTMLFormElement>) => {
    const btnDisabled = emailRef.current?.value && enquiryRef.current?.value ? false : true;
    setIsBtnDisabled(btnDisabled);
  };

  // TODO Need a type
  const handleSubmit = (e: any) => {
    e.preventDefault();
    
    if (!isBtnDisabled) {
      const form = e.target;
      const formData = new FormData(form);
      const formJson = Object.fromEntries(formData.entries());
      
      setInProgess(true); 
      
      axios.post(`${url}/contact`, formJson)
        .then(res => {
          if (res.status === 200) {
            setInProgess(false);
            // form already submitted - so dont submit again!

            setNotificationType(NotificationType.SUCCESS);
            setNotificationMsg("Message sent! We'll be in touch soon.");
            setIsBtnDisabled(true);
          }
        })
        .catch(err => {
          // TODO Throw error
          setInProgess(false);
          setNotificationType(NotificationType.DANGER);
          setNotificationMsg("Oops! Something went wrong!");
        });
    }
  };
  
  return (
    <div ref={contactUsRef} css={styles.ContactUsWrapper(theme)}>
      {notificationMsg && 
        <Notification kind={notificationType}>
          {notificationMsg} 
        </Notification>
      }
      <div>
        <Heading size={Size.H4_SMALL} styling={[styles.ExtraPadding]}>Contact us to find out more</Heading>
        <Heading size={Size.H4_SMALL} styling={[styles.MorePadding]}>
          The type of support we offer varies from client to client. By completing the
          form on this page, we will have an initial idea of what you need help with 
          and this will only take a few minutes to do.
          <br />
          <br />
          Once submitted, we will be in touch for a chat.
        </Heading>
        <Heading size={Size.H4_SMALL}>Thank you!</Heading>

        <form css={styles.ContactForm} method="post" onSubmit={handleSubmit} onChange={handleFormChange}>
          {/* Replace with AntDesign components */}
          <input ref={emailRef} name="contact" placeholder="Email or Phone Number" />
          <textarea ref={enquiryRef} name="enquiry" placeholder="Enquiry" />

          <Button type="submit" btnType={IButtonType.PRIMARY} disabled={isBtnDisabled}>
            {inProgress ? <FontAwesomeIcon icon={faSpinner} spin size="lg" /> : "Send"}
          </Button>
        </form>
      </div>
    </div>
  );
};