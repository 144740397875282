import { RefObject, useContext } from "react";
import ThemeContext from "../../utils/ThemeContext";
import { Testimonials } from "../Testimonials";
import { testimonials } from "../../utils/testimonials";
import styles from "./styles";

interface ISuccessStoriesProps {
  successStoriesRef: RefObject<HTMLDivElement>;
}

export const SuccessStories = ({
  successStoriesRef
}: ISuccessStoriesProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div ref={successStoriesRef} css={styles.SuccessStoriesWrapper(theme)}>
      <div>
        {/* <Heading styling={animations.slideInFromLeft('1s')} size={Size.H4} center>What people say...</Heading>  */}
        <Testimonials tests={testimonials} />
      </div>
    </div>
  );
};