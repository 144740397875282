import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ThemeContext from "../../utils/ThemeContext";
import { animations } from "../Shared/Animations";
import { Content } from "../Shared/Content";
import { Heading } from "../Shared/Heading";
import { Size } from "../Shared/Heading/types";
import SharedStyles from "../Shared/Styles";
import { IOurServicesProps } from "./types";
import { services } from "./services";
import styles from "./styles";
import useScroll, { ScrollDirection } from "../../hooks/use-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const OurServices = ({
  ourServicesRef
}: IOurServicesProps) => {
  
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const scrolling = useScroll();

  if (scrolling === ScrollDirection.DOWN) {
    // setShowTests(true);
    // TODO Only animate when it scrolls into view
  }
 
  const OurServicesText = [
    <Heading styling={animations.slideInFromLeft('1s')} size={Size.H4_SMALL}>To thrive, businesses need to be able to respond and act quickly. Waiting for an annual set of accounts could led to missed opportunities and unforeseen business and tax issues. This scenario, in our opinion, is not serving your businesses best interests."</Heading>,
    <div css={animations.slideInFromRight('2s')}>
      <Heading size={Size.H4} center>How we help</Heading>
      <br />
      <Heading size={Size.H4_SMALL}>We specialise in helping small and medium sized businesses like yours. We offer a comprehensive range of services (can this services word be a link that leads to the bottom of the page) and year round advice that few can match.</Heading>
      <br />
      <Heading size={Size.H4_SMALL}>Together we design a  tailored package based on your business needs and budget. What business issues are you facing? How often do you want to see us and when?</Heading>
    </div>,
    <div css={animations.slideInFromLeft('3s')}>
      <Heading size={Size.H4_SMALL}>As part of your team we develop a working relationship where we can offer meaningful advice to drive your business forward to meet your goals and objectives.</Heading>
      <br />
      <Heading size={Size.H4_SMALL}>Accountancy does not have to just involve help meeting government regulatory requirements, we can offer packages to provide monthly, quarterly, or half yearly accounts, tax planning, and cashflow forecasts.</Heading>
      <br />
      <Heading size={Size.H4_SMALL}>The benefits are obvious you have a better understanding of your day to day performance, you are also being guided by a Chartered Accountant.</Heading>
      <br />
      <Heading size={Size.H4_SMALL}>Does your business need a more hands on approach? Why not <Link to="/contact-us" css={SharedStyles.Link(theme, false)} >contact us</Link> and arrange a meeting.</Heading>
    </div>
  ];

  const OurServicesDescription = [
    <div css={styles.ServicesWrapper}>
      {services.map((s) => {
        return (
          <div key={s.name} css={styles.ServicePanel} onClick={() => navigate(s.route)}>
            <Heading size={Size.H4_SMALL}>{s.name}</Heading>
            <FontAwesomeIcon icon={s.icon} size="2x" />
          </div>
        );
      })}
    </div>
  ];

  return (
    <div ref={ourServicesRef} css={styles.OurServicesWrapper(theme)}>
      <Content
        title=""
        text={OurServicesText}
      >
      </Content>
      <Content
        title="Services"
        bgColor="var(--blue)"
        text={OurServicesDescription}
        centerTitle
      >
      </Content>
    </div>
  );
};