export interface ITheme {
  id: string;
  bg: string;
  fg: string;
  alt: string;
  color: string;
  colorAlt: string;
  arrow: string;
  icon: string;
}

export const breakpoints = {
  sm: 568,
  md: 768,
  lg: 992,
  xlg: 1200,
  xxlg: 1500
};

export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xLg: `@media screen and (min-width: ${breakpoints.xlg}px)`,
  xxLg: `@media screen and (min-width: ${breakpoints.xxlg}px)`,
};

const styles = {
  colours: {
    logo: {
      blue: '#043650',
      green: '#23ADB2',
      white: '#FFF'
    }
  },
};

const maxWidths = {
  sm: '',
  md: '750px;',
  lg: '',
  xlg: ''
}

const primary: ITheme = {
  id: 'primary',
  bg: styles.colours.logo.blue,
  fg: styles.colours.logo.green,
  alt: styles.colours.logo.white,
  color: '#FFF',
  colorAlt: '#FFF',
  arrow: '#FFF',
  icon: '#FFF',
};

const secondary: ITheme = {
  id: 'secondary',
  bg: styles.colours.logo.white,
  fg: styles.colours.logo.blue,
  alt: styles.colours.logo.green,
  color: '#FFF',
  colorAlt: 'black',
  arrow: styles.colours.logo.blue,
  icon: styles.colours.logo.blue,
};

const tertiary: ITheme = {
  id: 'tertiary',
  bg: 'red',
  fg: styles.colours.logo.blue,
  alt: styles.colours.logo.white,
  color: '#FFF',
  colorAlt: 'black',
  arrow: styles.colours.logo.green,
  icon: styles.colours.logo.green,
};

const appTheme = {
  ...styles,
  appTheme: primary,
  primary,
  secondary,
  tertiary,
  breakpoints: { ...breakpoints },
  mediaQueries: { ...mediaQueries },
  maxWidths: maxWidths
};

export default appTheme;