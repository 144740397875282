import { useNavigate } from "react-router-dom";
import { ILogoProps } from "./types";
import styles from "./styles";

export const Logo = ({
  src,
  alt,
  styling
}: ILogoProps) => {
  const navigate = useNavigate();
 
  return (
    <img css={[styles.Logo, styling]} src={src} alt={alt} onClick={() => navigate('/')} />
  );
};